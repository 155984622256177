<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" background-color="#384275" text-color="#D5DCFF" active-text-color="#FFFFFF" unique-opened router @select="selectMenu" text="LOT数据解析平台">
      <p class="lot_tit">LOT数据解析平台</p>
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <div class="flexAO">
                <!-- <img :src="item.icon" style="width: 26px; height: 26px;margin-right: 20px;" /> -->
                <span>{{ item.title }}</span>
              </div>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">{{ threeItem.title }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.i">{{ subItem.title }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <div class="flexAO">
              <!-- <img :src="item.icon" style="width: 26px; height: 26px;margin-right: 20px;" /> -->
              <span>{{ item.title }}</span>
            </div>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: [
        {
          index: 'transfer',
          title: '中转管理'
        },
        {
          index: 'vendor',
          title: '厂商管理'
        },
        {
          index: 'parameter',
          title: '参数管理'
        },
        {
          index: 'alarmName',
          title: '告警名称管理'
        },
        {
          index: 'type',
          title: '类型管理'
        },
        {
          index: 'model',
          title: '型号管理'
        },
        {
          index: 'dock',
          title: '对接管理'
        },
        {
          index: 'account',
          title: '账户管理'
        },
      ],
      checkedMenu: ''
    }
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace('/', '')
    }
  },
  watch: {
    
  },
  created () {
    this.checkedMenu = 'transfer'
  },
  methods: {
    selectMenu(index) {
      this.checkedMenu = index
    }
  }
}
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 220px;
}
.sidebar > ul {
  height: 100%;
}
/* .sidebar .el-menu--inline .el-menu-item {
  padding-left: 70px !important;
} */
.el-menu-item {
  height: 54px !important;
  line-height: 54px !important;
}
.el-menu-item.is-active{
  background: linear-gradient(88deg, #4566D8 0%, #5C7CEC 100%) !important;
}
.lot_tit {
  height: 54px;
  font-size: 20px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  line-height: 54px;
  margin-bottom: 16px;
}
</style>
