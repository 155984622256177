<template>
  <el-container class="home-container">
    <el-aside style="width: 220px;">
      <Sidebar></Sidebar>
    </el-aside>
    <el-container>
      <el-header class="flexCB" style="height:54px;">
        <div>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, index) in breadlist" :key="index">{{ item.meta.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="cursor: pointer;">
          <!-- <el-dropdown>
            <i class="el-icon-setting" style="margin-right: 10px"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>查看</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span style="margin-right:24px;">通知设置</span> -->
          <i class="el-icon-setting" style="margin-right: 10px"></i>
          <span @click="loginOut">退出登录</span>
        </div>
      </el-header>
      <el-main class="setting_main" style="padding: 16px;">
        <div class="setting_cont">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar  from '@/components/sidebar.vue'

export default {
  components: {
    Sidebar
  },
  data () {
    return {
      breadlist: []
    }
  },
  created () {
    // this.getbreadlist()
  },
  // 监听属性
  watch: {
    // 监听路由
    $route(val) {
      // 调用获取路由数组方法
      this.getbreadlist(val);
    }
  },
  methods: {
    getbreadlist(val) {
      // let matched = this.$route.matched;
      // 过滤路由matched对象
      if (val.matched) {
        let matched = val.matched.filter(item => item.meta && item.meta.title);
        // 拿到过滤好的路由v-for遍历出来
        this.breadlist = matched;
      }
    },
    /* 退出登录 */
    loginOut () {
      this.$router.push({ path: '/login' })
      window.localStorage.removeItem('user')
    }
  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  background:#F0F3F8;
  display: flex;
}
.el-header {
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0px 20px;
  background: #FFFFFF;
  color: #6F7E90;
}
.el-aside {
  background: #384275;
  color: #FFFFFF;
  text-align: left;
  line-height: 70px;
}
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}
</style>

